import React from "react";
import Header from "./Components/Header/Header";
import Main from "./Components/Body/Main";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="scroll-smooth ">
      <Header/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
