import React from 'react'

export default function LeftArrow() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13C1 13 6.99999 8.5811 7 7C7.00001 5.4188 1 0.999999 1 0.999999" stroke="#141B34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}
