const NavigationData =[
    {
        "id":1,
        "name":"Ambidexters",
        "href":"https://ambidexters.vercel.app",
        "description":"Tech-enabled solutions revolutionizing business operations.",
    },
    {
        "id":2,
        "name":"SkillHubs",
        "href":"https://theskillhubs.com",
        "description":"Empowering corporate growth through Training, Development, and Consulting.",
    },
    {
        "id":3,
        "name":"iMOMAZIN",
        "href":"https://imomazin.com",
        "description":"Strategic insights and leadership by Dr. Imo Mazin.",
    },
]


const FooterNavigation =[
    {
        "id":1,
        "name":"Privacy",
        "href":"#",
    },
    {
        "id":2,
        "name":"Products",
        "href":"#",
    },
    {
        "id":3,
        "name":"Terms of Service",
        "href":"#",
    },
]

export  {NavigationData,FooterNavigation}
