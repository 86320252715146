import React from 'react'
import LogoImage from '../../Assets/LogoImage.png'
import { FooterNavigation } from '../NavigationsData/Navigation'

function Footer() {
  return (
    <footer className='w-full h-[75px] bg-[#E8E8E8] font-sans'>
        <div className='mx-5 lg:mx-20 h-full lg:w-[55%] flex justify-between'>
            <div className='flex flex-row '>
                <img src={LogoImage} alt="" className='w-[45px] h-[45px] my-auto lg:w-[75px] lg:h-[75px]' />
                <div className='my-auto'>
                    <span className='font-bold font-sans text-[12px] lg:text-[23px]'>VLV</span>
                    {/* <span className='font-bold font-sans text-[16px] lg:text-[23px]'></span> */}
                </div>
            </div>

            <div className='flex gap-x-6 my-auto mx-4'>
                {
                   FooterNavigation.map((footNav,i)=>(
                    <a href={footNav.href} key={i}>
                        <div className='lg:text-[17px] text-[8px] md:text-[12px]'>
                            {footNav.name}
                        </div>
                    </a>
                   )) 
                }
            </div>
        </div>
    </footer>
  )
}

export default Footer
