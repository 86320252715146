import React from 'react'
import LeftArrow from '../SVG/LeftArrow'
import Star from '../SVG/Star'
import {NavigationData} from '../NavigationsData/Navigation'

function Main() {
  return (
    <div className='lg:relative w-full overflow-x-hidden lg:overflow-hidden '>
        {/*  */}
        <div className='group bg-[#E8E8E8] cursor-pointer rounded-full w-[335px] h-[44px] flex px-4 gap-x-4 mx-auto my-20 font-sans'>
            <span className='my-auto font-normal  text-[14px] text-[#646464]'>Empowering Innovation</span>
            <span className='my-auto font-normal text-[13px] text-[#171717]'>Across Industries</span>
            <span className='my-auto group-hover:translate-x-2 transition-transform delay-100 ease-linear'><LeftArrow/></span>
        </div>

        {/*  */}
        <div className='lg:w-[900px] flex flex-col gap-y-10 justify-center px-6 mx-auto'>
            <p className='lg:text-[80px] font-normal text-[62px] text-center text-[#1E1E1E] font-Orelega-One'>
                Innovating <span className='text-blue-700'>Today</span> for a 
                Smarter <span className='text-blue-700'>Tomorrow</span>
            </p>
            <p className='text-[#454545] lg:font-light lg:text-[25px] text-[19px] text-center'>
                Explore our ecosystem of transformative solutions driving business 
                success and organizational excellence.
            </p>
        </div>

        {/* Images */}
        <img src="https://vlv-ambidexters.s3.eu-north-1.amazonaws.com/VivalavidaImages/BodyImg1.png" alt="Left Thumb up" className='hidden lg:block lg:absolute lg:-left-24 lg:top-[25%] w-[323px] h-[273px] -z-50'/>
        <Star className='hidden lg:block lg:absolute -right-44 top-[15%] w-[582.98px] h-[582.98px] transform -rotate-12 -z-50'/>
        <img src="https://vlv-ambidexters.s3.eu-north-1.amazonaws.com/VivalavidaImages/BodyImg2.png" alt="Right Thumb up" className='hidden lg:block lg:absolute top-[30%] -right-10 w-[129px] h-[306px] transform -rotate-45'/>

        {/* Links to the other platform */}
        <div className='mx-20 2xl:mx-48 lg:h-[169px] gap-y-6 my-28 z-50 flex flex-col lg:flex-row justify-between font-sans'>
            {
                NavigationData.map((navigation,i)=>(
                    <a href={navigation.href} key={i} className="group z-50" target="_blank" rel="noreferrer">
                        <div className='lg:w-[379px] h-full lg:h-[169px] bg-white px-6 py-3 shadow-2xl rounded-2xl group-hover:scale-110 transition-transform delay-100 ease-linear z-50'>
                            <h3 className='text=[#171717] text-[23px] font-normal mb-4'>{navigation.name}</h3>
                            <p className='font-light text-[16px] text-[#171717] text-opacity-80'>{navigation.description}</p>
                            <span className='flex gap-x-2 mt-2'>
                                <p className='font-normal text-[16px]'>Explore Now</p>
                                <span className='my-auto group-hover:translate-x-2 transition-transform delay-100 ease-linear'><LeftArrow/></span>
                            </span>
                        </div>
                    </a>
                ))
            }
        </div>
    </div>
  )
}

export default Main
