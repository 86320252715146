import React from 'react'

export default function Star({className}) {
  return (
<svg width="292" height="583" viewBox="0 0 292 583" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M200.933 13.9723L244.413 74.5197L258.541 1.3284L288.943 69.3889L317.515 0.539555L333.594 73.3268L375.441 11.638L376.538 86.172L429.946 34.1694L416.017 107.399L478.8 67.2113L450.414 136.138L520.002 109.411L478.322 171.213L551.865 159.041L498.598 211.188L573.086 214.069L510.412 254.427L582.795 272.242L513.28 299.158L580.594 331.18L507.085 343.552L566.575 388.468L492.08 385.79L541.31 441.761L468.879 424.143L505.835 488.879L438.434 457.04L461.601 527.891L401.989 483.136L410.419 557.2L361.037 501.362L354.385 575.607L317.254 510.972L295.794 582.358L272.434 511.571L237.042 577.177L228.411 503.136L180.537 560.275L186.987 486.012L128.591 532.345L149.858 460.901L83.3311 494.53L118.544 428.829L46.6099 448.379L94.3281 391.11L19.9309 395.78L78.2004 349.289L4.38642 338.887L70.8217 305.077L0.612826 280.03L72.494 260.285L8.76461 221.618L83.1488 216.746L28.508 166.042L102.35 176.242L59.0348 115.577L129.311 140.433L99.0951 72.2905L162.929 110.784L147.049 37.9536L201.827 88.5091L200.933 13.9723Z" fill="#B7B7B7"/>
</svg>

  )
}
