import React from 'react'

export default function XLogo() {
  return (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.5 12.2424C2.5 7.76403 2.5 5.52486 3.89124 4.13361C5.28249 2.74237 7.52166 2.74237 12 2.74237C16.4783 2.74237 18.7175 2.74237 20.1088 4.13361C21.5 5.52486 21.5 7.76403 21.5 12.2424C21.5 16.7207 21.5 18.9599 20.1088 20.3512C18.7175 21.7424 16.4783 21.7424 12 21.7424C7.52166 21.7424 5.28249 21.7424 3.89124 20.3512C2.5 18.9599 2.5 16.7207 2.5 12.2424Z" stroke="#171717" stroke-width="1.5" stroke-linejoin="round"/>
  <path d="M16.5 12.2424C16.5 14.7277 14.4853 16.7424 12 16.7424C9.51472 16.7424 7.5 14.7277 7.5 12.2424C7.5 9.75709 9.51472 7.74237 12 7.74237C14.4853 7.74237 16.5 9.75709 16.5 12.2424Z" stroke="#171717" stroke-width="1.5"/>
  <path d="M17.5078 6.74237H17.4988" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>


  )
}
