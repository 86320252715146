import React from 'react'
import XLogo from '../SVG/XLogo'
import InstaLogo from '../SVG/InstaLogo'
import LogoImage from '../../Assets/LogoImage.png'


function Header() {
  return (
    <div className='w-full h-[100px] border-b-2 py-7'>
        <div className=' h-[44.48px] mx-5 lg:mx-20 px-5 flex justify-between lg:justify-normal'>
            {/* Other Brands */}
            <div className='flex w-[126px]'>
                <div className='my-auto flex gap-x-8'>
                    <a href="https://www.instagram.com/ambidexters_llc" target="_blank" rel="noopener noreferrer">
                        <XLogo/>
                    </a>
                    <a href="https://www.linkedin.com/company/ambidexters-inc/" target="_blank" rel="noopener noreferrer">
                        <InstaLogo/>
                    </a>
                </div>
            </div>

            {/* Logo */}
            <div className='flex gap-x-2 lg:mx-[30%] 2xl:mx-[37.5%]'>
                <img src={LogoImage} alt="BrandLogo" className='w-16 h-16 rounded-full -mt-3' />
                <span className='font-bold font-sans text-[23px] lg:hidden -ml-5'>VLV</span>
                <span className='font-bold font-sans text-[23px] hidden lg:block -ml-5'>Viva La Vida</span>
            </div>
        </div>
    </div>
  )
}

export default Header
