import React from 'react'

export default function InstaLogo() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 9.74237H4C3.05719 9.74237 2.58579 9.74237 2.29289 10.0353C2 10.3282 2 10.7996 2 11.7424V20.2424C2 21.1852 2 21.6566 2.29289 21.9495C2.58579 22.2424 3.05719 22.2424 4 22.2424H4.5C5.44281 22.2424 5.91421 22.2424 6.20711 21.9495C6.5 21.6566 6.5 21.1852 6.5 20.2424V11.7424C6.5 10.7996 6.5 10.3282 6.20711 10.0353C5.91421 9.74237 5.44281 9.74237 4.5 9.74237Z" stroke="#171717" stroke-width="1.5"/>
    <path d="M6.5 4.49237C6.5 5.73501 5.49264 6.74237 4.25 6.74237C3.00736 6.74237 2 5.73501 2 4.49237C2 3.24973 3.00736 2.24237 4.25 2.24237C5.49264 2.24237 6.5 3.24973 6.5 4.49237Z" stroke="#171717" stroke-width="1.5"/>
    <path d="M12.326 9.74237H11.5C10.5572 9.74237 10.0858 9.74237 9.79289 10.0353C9.5 10.3282 9.5 10.7996 9.5 11.7424V20.2424C9.5 21.1852 9.5 21.6566 9.79289 21.9495C10.0858 22.2424 10.5572 22.2424 11.5 22.2424H12C12.9428 22.2424 13.4142 22.2424 13.7071 21.9495C14 21.6566 14 21.1852 14 20.2424L14.0001 16.7425C14.0001 15.0857 14.5281 13.7425 16.0879 13.7425C16.8677 13.7425 17.5 14.4141 17.5 15.2425V19.7425C17.5 20.6853 17.5 21.1567 17.7929 21.4496C18.0857 21.7425 18.5572 21.7425 19.5 21.7425H19.9987C20.9413 21.7425 21.4126 21.7425 21.7055 21.4497C21.9984 21.1569 21.9985 20.6856 21.9987 19.743L22.0001 14.2426C22.0001 11.7574 19.6364 9.74261 17.2968 9.74261C15.9649 9.74261 14.7767 10.3955 14.0001 11.4164C14 10.7863 14 10.4713 13.8632 10.2374C13.7765 10.0892 13.6531 9.9659 13.505 9.87924C13.2711 9.74237 12.9561 9.74237 12.326 9.74237Z" stroke="#171717" stroke-width="1.5" stroke-linejoin="round"/>
    </svg>

  )
}
